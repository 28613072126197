import type { AlertTestIdAttributes } from '@data/notification/types';
import { useVehicleAlertContent } from '@data/notification/vehicle';
import type { VehicleAlert } from '@data/notification/vehicle/types';
import { Box, Typography } from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React from 'react';

type Props = {
  alert: VehicleAlert;
  testIdAttrs?: AlertTestIdAttributes;
};

const VehicleAlertContent: React.FC<Props> = ({
  alert,
  testIdAttrs,
}: Props) => {
  const { title, vehicleName, message, linkButton } =
    useVehicleAlertContent(alert);

  if (isNullOrUndefined(alert.vehicle)) return null;

  return (
    <Box maxWidth={400}>
      <Typography variant="subtitle2" data-testid={testIdAttrs?.titleTestId}>
        {title}
      </Typography>
      <Typography variant="caption">{alert.notificatedAt}</Typography>
      <Typography data-testid={testIdAttrs?.messageTestId}>
        {vehicleName}
      </Typography>
      {message}
      {linkButton}
    </Box>
  );
};

export default React.memo(VehicleAlertContent);
