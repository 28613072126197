import i18next from 'i18next';
import { initReactI18next, useTranslation } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en, ja } from './locales';
import { useCallback } from 'react';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      resources: {
        en: {
          translation: en,
        },
        ja: {
          translation: ja,
        },
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    },
    () => {
      if (!['ja', 'en-US'].includes(i18next.language)) {
        i18next.changeLanguage('en-US');
      }
    },
  );

type DocumentPath =
  | string
  | { [key: string]: string; ja: string; 'en-US': string };

export const useDocumentLink = () => {
  const { i18n } = useTranslation();

  /**
   * @param path Path が共通の場合は文字列を、分けたい場合はオブジェクトを渡す
   */
  const createDocumentLink = useCallback(
    (path: DocumentPath) => {
      const root = `https://docs.web.auto${
        i18n.language === 'ja' ? '' : '/en'
      }`;
      if (!path) return `${root}/`;

      if (typeof path === 'string') {
        return `${root}${path}`;
      }
      return `${root}${path[i18n.language]}`;
    },
    [i18n.language],
  );

  return createDocumentLink;
};
