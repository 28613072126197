import isNullOrUndefined from '@utils/isNullOrUndefined';
import type valueof from '@utils/valueof';
import { useCallback } from 'react';
import { useMount } from 'react-use';
import { atom, useRecoilState } from 'recoil';
import store from 'store2';

export type AlertSetting = {
  mute: boolean;
  volume: number;
};

export type VehicleSetting = {
  alwaysCenter: boolean;
  displayRoute: boolean;
};

export type Settings = {
  alert: AlertSetting;
  vehicle: VehicleSetting;
};

const alertDefaultSetting: AlertSetting = {
  mute: false,
  volume: 0.5,
};

const vehicleDefaultSetting: VehicleSetting = {
  alwaysCenter: true,
  displayRoute: true,
};

const defaultSettings: Settings = {
  alert: alertDefaultSetting,
  vehicle: vehicleDefaultSetting,
};

const KEY_NAME = 'settings';

export const settingAtom = atom<Settings>({
  key: 'settingAtom',
  default: store.get(KEY_NAME) ?? defaultSettings,
});

export const useSettings = (): {
  setting: Settings;
  updateAlert: (key: keyof AlertSetting, value: valueof<AlertSetting>) => void;
  updateVehicle: (
    key: keyof VehicleSetting,
    value: valueof<VehicleSetting>,
  ) => void;
} => {
  const [setting, setSetting] = useRecoilState(settingAtom);

  const update = useCallback(
    (key: keyof Settings, value: valueof<Settings>) => {
      const saveSetting = { ...setting, [key]: value };
      store.local.set(KEY_NAME, saveSetting);
      setSetting(saveSetting);
    },
    [setSetting, setting],
  );

  const updateAlert = useCallback(
    (key: keyof AlertSetting, value: valueof<AlertSetting>) => {
      const saveAlert = { ...setting.alert, [key]: value } as AlertSetting;
      update('alert', { ...setting.alert, ...saveAlert });
    },
    [update, setting.alert],
  );

  const updateVehicle = useCallback(
    (key: keyof VehicleSetting, value: valueof<VehicleSetting>) => {
      const saveVehicle = {
        ...setting.vehicle,
        [key]: value,
      } as VehicleSetting;
      update('vehicle', { ...setting.vehicle, ...saveVehicle });
    },
    [update, setting.vehicle],
  );

  useMount(() => {
    const saved = store.local.get(KEY_NAME);
    if (isNullOrUndefined(saved)) {
      // ローカルストレージに `settings` が無い場合デフォルト値を保存
      store.local.set(KEY_NAME, defaultSettings);
      return;
    }
  });

  return {
    setting,
    updateAlert,
    updateVehicle,
  };
};
