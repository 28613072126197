import React from 'react';
import { useGoogleMap } from '@react-google-maps/api';
import { useEffect } from 'react';
import { computeLatLngBounds } from '@utils/googlemaps';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useSettings } from '@data/settings';
import { useMount } from 'react-use';
import type { LatLng } from '@data/fms/place/types';

type Props = Partial<{
  latLngs: google.maps.LatLngLiteral[] | null;
  vehicleLocation: LatLng | null;
}>;

const MapSetCenter: React.FC<Props> = ({ latLngs, vehicleLocation }: Props) => {
  const map = useGoogleMap();
  const { setting } = useSettings();

  useMount(() => {
    if (!map || isNullOrUndefined(vehicleLocation)) return;
    map.setCenter(vehicleLocation);
  });

  useEffect(() => {
    if (
      !map ||
      isNullOrUndefined(vehicleLocation) ||
      !setting.vehicle.alwaysCenter
    )
      return;
    // VehicleLocationが指定されている場合はそちらを優先
    map.setCenter(vehicleLocation);
  }, [map, setting.vehicle.alwaysCenter, vehicleLocation]);

  useEffect(() => {
    if (!map) return;
    if (!isNullOrUndefined(latLngs) && latLngs.length > 0) {
      map.fitBounds(computeLatLngBounds(latLngs));
    }
  }, [latLngs, map]);

  return null;
};

export default React.memo(MapSetCenter);
