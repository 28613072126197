import type { ForwardRefExoticComponent, RefAttributes } from 'react';
import React, { useMemo } from 'react';
import type { VehicleAlert, VehicleAlertLink } from './types';
import styled from '@emotion/styled';
import type { AlertColor, ButtonProps } from '@mui/material';
import { Button as MuiButton, Typography } from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { SharedProps, VariantType } from 'notistack';
import { Trans, useTranslation } from 'react-i18next';
import { useConcatWord } from '@utils/i18n';
import { atom, useRecoilValue } from 'recoil';
import { useDocumentLink } from '@data/i18n';
import type { VehicleAlertType } from './types';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useRootPath } from '@data/fms/environment/hooks';
import { environmentOptionAtom } from '@data/fms/environment/states';
import { OpenInNew } from '@mui/icons-material';

export const vehicleAlertListAtom = atom<VehicleAlert[]>({
  key: 'vehicleAlertListAtom',
  default: [],
});

export const confirmedCountAtom = atom({
  key: 'confirmedCountAtom',
  default: 0,
});

export const getVehicleAlertLevel = (type: VehicleAlertType): VariantType => {
  if (
    type === 'vehicleError' ||
    type === 'agentError' ||
    type === 'mapUpdateFailure' ||
    type === 'agentUpdateFailure' ||
    type === 'firmwareUpdateFailure' ||
    type === 'individualParameterTampered'
  ) {
    return 'error';
  }
  if (
    type === 'stuckAlarm' ||
    type === 'batteryAlarm' ||
    type === 'agentWarning' ||
    type === 'individualParameterSuggested' ||
    type === 'telemetryArrivalErrorAlarm'
  ) {
    return 'warning';
  }
  if (type === 'disconnect') {
    return 'info';
  }
  return 'info';
};

export const useVehicleAlertContent = (alert: VehicleAlert) => {
  const { t } = useTranslation();
  const { concat } = useConcatWord();
  const rootPath = useRootPath();
  const environmentOption = useRecoilValue(environmentOptionAtom);
  const createDocumentLink = useDocumentLink();

  /**
   * Level
   */
  const level = useMemo(
    (): SharedProps['variant'] | AlertColor => getVehicleAlertLevel(alert.type),
    [alert.type],
  );

  /**
   * Title
   */
  const title = useMemo(() => {
    // 車両エラー
    if (alert.type === 'vehicleError') {
      return t('vehicle.error');
    }
    // Agentエラー
    if (alert.type === 'agentError') {
      return concat(['Agent', t('vehicle.error')]);
    }
    // Agentワーニング
    if (alert.type === 'agentWarning') {
      return concat(['Agent', t('vehicle.warning')]);
    }
    // 立ち往生
    if (alert.type === 'stuckAlarm') {
      return t('vehicle.stuck');
    }
    // 未接続
    if (alert.type === 'disconnect') {
      return t('vehicle.telemetry.status.disconnected');
    }
    // バッテリー残量
    if (alert.type === 'batteryAlarm') {
      return concat([t('vehicle.telemetry.battery'), t('common.general.low')]);
    }
    // ネットワーク警告
    if (alert.type === 'telemetryArrivalErrorAlarm') {
      return t('vehicle.telemetry.telemetry_arrival_error');
    }
    // 更新失敗
    if (
      alert.type === 'mapUpdateFailure' ||
      alert.type === 'agentUpdateFailure' ||
      alert.type === 'firmwareUpdateFailure'
    ) {
      return t('notification.update_failure.title', { type: alert.type });
    }
    if (
      alert.type === 'individualParameterImported' ||
      alert.type === 'individualParameterSuggested'
    ) {
      return t('notification.individual_parameter.suggestion.title');
    }
    // 車両固有パラメータ改変あり
    if (alert.type === 'individualParameterTampered') {
      return t('notification.individual_parameter.tampered.title');
    }
    return '';
  }, [alert.type, t, concat]);

  /**
   * Vehicle Name
   */
  const vehicleName = useMemo(() => {
    return (
      alert.vehicle &&
      `${t('vehicle.vehicle_name')}: ${alert.vehicle.vehicle_name}`
    );
  }, [alert, t]);

  /**
   * Message
   */
  const message = useMemo(() => {
    // Battery
    if (alert.type === 'batteryAlarm') {
      return (
        <AlertContentTypography>
          {t('common.alert.battery', {
            count: alert.vehicle.telemetry.battery,
          })}
        </AlertContentTypography>
      );
    }
    // Agentエラー
    if (
      alert.type === 'agentError' &&
      !isNullOrUndefined(alert.vehicle) &&
      !isNullOrUndefined(alert.agentErrorMessage)
    ) {
      return (
        <AlertContentTypography sx={{ wordBreak: 'break-all' }}>
          {alert.agentErrorMessage}
        </AlertContentTypography>
      );
    }
    // Agentワーニング
    if (
      alert.type === 'agentWarning' &&
      !isNullOrUndefined(alert.vehicle) &&
      !isNullOrUndefined(alert.agentWarningMessage)
    ) {
      return (
        <AlertContentTypography>
          {alert.agentWarningMessage}
        </AlertContentTypography>
      );
    }
    // 更新失敗
    if (
      alert.type === 'mapUpdateFailure' ||
      alert.type === 'agentUpdateFailure' ||
      alert.type === 'firmwareUpdateFailure'
    ) {
      return (
        <AlertContentTypography sx={{ whiteSpace: 'pre-line' }}>
          {t('notification.update_failure.message', { type: alert.type })}
        </AlertContentTypography>
      );
    }
    // 車両固有パラメータ候補値
    if (alert.type === 'individualParameterSuggested') {
      return (
        <AlertContentTypography>
          {t('notification.individual_parameter.suggestion.message.suggested')}
        </AlertContentTypography>
      );
    }
    if (alert.type === 'individualParameterImported') {
      return (
        <AlertContentTypography>
          {t('notification.individual_parameter.suggestion.message.imported')}
        </AlertContentTypography>
      );
    }
    // 車両固有パラメータ改変あり
    if (alert.type === 'individualParameterTampered') {
      return (
        <AlertContentTypography>
          {t('notification.individual_parameter.tampered.message')}
        </AlertContentTypography>
      );
    }
    if (alert.type === 'telemetryArrivalErrorAlarm') {
      return (
        <AlertContentTypography>
          <Trans
            i18nKey="notification.telemetry_arrival_error"
            components={{
              docs: (
                <a
                  href={createDocumentLink({
                    ja: '/user-manuals/fleet-management-system/webauto-agent/troubleshooting#%E3%83%8D%E3%83%83%E3%83%88%E3%83%AF%E3%83%BC%E3%82%AF',
                    'en-US':
                      '/user-manuals/fleet-management-system/webauto-agent/troubleshooting#network',
                  })}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  link
                </a>
              ),
            }}
          />
        </AlertContentTypography>
      );
    }
    return null;
  }, [alert, t, createDocumentLink]);

  /**
   * Link Button
   */
  const linkButton = useMemo(() => {
    if (
      alert.type === 'disconnect' ||
      alert.type === 'telemetryArrivalErrorAlarm' ||
      isNullOrUndefined(alert.vehicle)
    )
      return null;

    const getLink = (): VehicleAlertLink => {
      if (alert.type === 'vehicleError') {
        // エラー一覧
        return {
          label: t('page.error_detail'),
          to: `${rootPath}error-detail/${alert.vehicle.vehicle_id}/?condition=${environmentOption?.error_alert_condition}`,
        };
      } else if (
        alert.type === 'mapUpdateFailure' ||
        alert.type === 'agentUpdateFailure' ||
        alert.type === 'firmwareUpdateFailure'
      ) {
        // 車両一覧
        return {
          label: t('page.vehicle_management'),
          to: `${rootPath}vehicle-management/`,
        };
      } else if (
        alert.type === 'individualParameterSuggested' ||
        alert.type === 'individualParameterImported'
      ) {
        // 車両状態確認 -> 車両固有パラメータ
        return {
          label: t('vehicle_condition.list.individual_parameter'),
          to: `${rootPath}vehicle-condition/parameter/${alert.vehicle.vehicle_id}/?type=suggestion`,
        };
      } else if (alert.type === 'individualParameterTampered') {
        // 車両状態確認 -> 車両固有パラメータ
        return {
          label: t('vehicle_condition.list.individual_parameter'),
          to: `${rootPath}vehicle-condition/parameter/${alert.vehicle.vehicle_id}/`,
        };
      } else if (alert.type === 'agentError') {
        // Agent エラー -> ドキュメントへのリンク
        return {
          label: t('notification.vehicle_agent_error.document_link'),
          to: createDocumentLink(
            '/user-manuals/fleet-management-system/fms-console/error-notification/',
          ),
          isExternal: true,
        };
      } else {
        // 車両詳細
        return {
          label: t('page.vehicle_detail'),
          to: `${rootPath}vehicle/${alert.vehicle.vehicle_id}/`,
        };
      }
    };

    const link = getLink();
    const color = level as 'success' | 'warning' | 'info';

    if (link.isExternal) {
      return (
        <Button
          href={link.to}
          size="small"
          variant="outlined"
          color={color}
          target="_blank"
          rel="noreferrer noopener"
          endIcon={<OpenInNew />}
        >
          {link.label}
        </Button>
      );
    }

    return (
      <LinkButton
        component={Link}
        to={link.to}
        size="small"
        variant="outlined"
        color={color}
      >
        {link.label}
      </LinkButton>
    );
  }, [alert, t, rootPath, environmentOption, level, createDocumentLink]);

  return {
    level,
    title,
    vehicleName,
    message,
    linkButton,
  };
};

const Button = styled(MuiButton)<ButtonProps & { target?: string }>`
  margin-top: ${({ theme }) => theme.spacing(2)};
  background-color: white;
  font-size: 12px;
  padding: 2px 9px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

const LinkButton = styled(Button)<
  {
    component: ForwardRefExoticComponent<
      LinkProps & RefAttributes<HTMLAnchorElement>
    >;
    to: string;
  } & ButtonProps
>``;

const AlertContentTypography = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(2)};

  a {
    color: inherit;
    text-decoration: underline;
    font-weight: bold;
  }
`;
