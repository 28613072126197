import { environmentAtom } from '@data/fms/environment/states';
import { useCallback, useMemo } from 'react';
import { atom, useRecoilValue } from 'recoil';
import type { AssertScope } from './types';

export const assertAtom = atom<AssertScope>({
  key: 'scopeAtom',
  default: {
    featureScope: '',
    scope: '',
    asserted: false,
  },
});

/**
 * 走行環境作成権限を持っているかどうか
 */
export const hasCreateEnvironmentScopeAtom = atom({
  key: 'hasCreateEnvironmentScopeAtom',
  default: false,
});

/**
 * エリアマップ情報取得権限を持っているかどうか
 */
export const hasDescribeAreaMapScopeAtom = atom({
  key: 'hasDescribeAreaMapScopeAtom',
  default: false,
});

/**
 * エリアマップの地図をダウンロードできる権限を持っているかどうか
 */
export const hasDownloadAreaMapScopeAtom = atom({
  key: 'hadDownloadAreaMapScopeAtom',
  default: false,
});

/**
 * エリアマップのサービスクォータ情報取得権限を持っているかどうか
 */
export const hasDescribeAreaMapServiceQuotaScopeAtom = atom({
  key: 'hasDescribeAreaMapServiceQuotaScopeAtom',
  default: false,
});

export const FEATURE_SCOPES = {
  VideoStreaming: 'VideoStreaming',
  Report: 'Report',
  // NOTE: 今後再利用するかもしれないのでコメントアウト
  // SetScheduleExpiration: 'SetScheduleExpiration',
  BasicSchedule: 'BasicSchedule',
  LoopSchedule: 'LoopSchedule',
  ScheduleHistory: 'ScheduleHistory',
  MaintenanceSchedule: 'MaintenanceSchedule',
  VoiceCall: 'VoiceCall',
  MultiVehicleCoordination: 'MultiVehicleCoordination',
  IndividualParamsReg: 'IndividualParamsReg',
  CandidateCalibrationParams: 'CandidateCalibrationParams',
  IndividualParamsEdit: 'IndividualParamsEdit',
  NoEntryZoneManagement: 'NoEntryZoneManagement',
  GoalModification: 'GoalModification',
} as const;

/**
 * <project_id>:environment:<environment_id>=<operation> のスコープ
 */
export const SCOPES = {
  // 走行環境
  Describe: 'Describe',
  Update: 'Update',
  Delete: 'Delete',
  // 車両
  DescribeVehicleMetadata: 'DescribeVehicleMetadata',
  DescribeVehicleTelemetry: 'DescribeVehicleTelemetry',
  CreateVehicle: 'CreateVehicle',
  DeleteVehicle: 'DeleteVehicle',
  ResumeVehicle: 'ResumeVehicle',
  StartVehicle: 'StartVehicle',
  SuspendVehicle: 'SuspendVehicle',
  EmergencyStopVehicle: 'EmergencyStopVehicle',
  UpdateVehicleName: 'UpdateVehicleName',
  UpdateVehicleCanStart: 'UpdateVehicleCanStart',
  UpdateVehicleAcceptableOrder: 'UpdateVehicleAcceptableOrder',
  UpdateVehicleStuckAlarmSnooze: 'UpdateVehicleStuckAlarmSnooze',
  UpdateVehicleEnvironment: 'UpdateVehicleEnvironment',
  UpdateVehicleDescription: 'UpdateVehicleDescription',
  UpdateVehicleInitialPoint: 'UpdateVehicleInitialPoint',
  UpdateVehicleSoftware: 'UpdateVehicleSoftware',
  UpdateVehicleMap: 'UpdateVehicleMap',
  UpdateRosbagSaveMode: 'UpdateRosbagSaveMode',
  UpdateAutowareAccessMode: 'UpdateAutowareAccessMode',
  UnlockIntersectionMediation: 'UnlockIntersectionMediation',
  OperateVehicleDoor: 'OperateVehicleDoor',
  // 車両固有パラメータ
  UploadCalibrationParameter: 'UploadCalibrationParameter',
  DownloadCalibrationParameter: 'DownloadCalibrationParameter',
  DescribeCalibrationParameter: 'DescribeCalibrationParameter',
  DeployCalibrationParameter: 'DeployCalibrationParameter',
  UpdateCalibrationParameterMetadata: 'UpdateCalibrationParameterMetadata',
  ImportCalibrationParameterCandidates: 'ImportCalibrationParameterCandidates',
  ApproveCalibrationParameterCandidates:
    'ApproveCalibrationParameterCandidates',
  DescribeCalibrationParameterEditConfigs:
    'DescribeCalibrationParameterEditConfigs',
  CreateNewCalibrationParameterWithEdited:
    'CreateNewCalibrationParameterWithEdited',
  UpdateCalibrationParameterSettings: 'UpdateCalibrationParameterSettings',
  // スケジュール
  CancelSchedule: 'CancelSchedule',
  CompleteScheduleTask: 'CompleteScheduleTask',
  CreateBasicSchedule: 'CreateBasicSchedule',
  CreateLoopSchedule: 'CreateLoopSchedule',
  CreateMaintenanceSchedule: 'CreateMaintenanceSchedule',
  UpdateScheduleTag: 'UpdateScheduleTag',
  // NOTE: 今後再利用するかもしれないのでコメントアウト
  // CreateScheduleWithExpiration: 'CreateScheduleWithExpiration',
  DescribeSchedule: 'DescribeSchedule',
  ResetSchedule: 'ResetSchedule',
  // 走行環境オプション
  DescribeWebAutoAppOption: 'DescribeWebAutoAppOption',
  UpdateWebAutoAppOption: 'UpdateWebAutoAppOption',
  // 地図
  DescribePlace: 'DescribePlace',
  DescribeRoute: 'DescribeRoute',
  SearchRoute: 'SearchRoute',
  SearchPartialRoute: 'SearchPartialRoute',
  DescribeLaneletsCenterLinePoints: 'DescribeLaneletsCenterLinePoints',
  GenerateAreaMapVersionLaneletsCenterLinePoints:
    'GenerateAreaMapVersionLaneletsCenterLinePoints',
  ChangeAreaMap: 'ChangeAreaMap',
  RestoreAreaMapVersion: 'RestoreAreaMapVersion',
  DescribeAreaMap: 'DescribeAreaMap',
  DescribeNoEntryZoneSetting: 'DescribeNoEntryZoneSetting',
  CreateNoEntryZoneSetting: 'CreateNoEntryZoneSetting',
  UpdateNoEntryZoneSetting: 'UpdateNoEntryZoneSetting',
  DeleteNoEntryZoneSetting: 'DeleteNoEntryZoneSetting',
  ApplyNoEntryZoneSetting: 'ApplyNoEntryZoneSetting',
  RevokeNoEntryZoneSetting: 'RevokeNoEntryZoneSetting',
  // データ基盤
  DescribeProbe: 'DescribeProbe',
  DescribeBag: 'DescribeBag',
  DescribeReport: 'DescribeReport',
  DescribeVehicleError: 'DescribeVehicleError',
  CreateDrivingNote: 'CreateDrivingNote',
  UpdateDrivingNote: 'UpdateDrivingNote',
  DescribeDrivingNote: 'DescribeDrivingNote',
  DeleteDrivingNote: 'DeleteDrivingNote',
  DescribeLogFile: 'DescribeLogFile',
  DescribeScanMatchingScore: 'DescribeScanMatchingScore',
  DescribeAnalysisItems: 'DescribeAnalysisItems',
  DescribeVehicleCondition: 'DescribeVehicleCondition',
  DescribeMaintenanceLog: 'DescribeMaintenanceLog',
  DescribeWeatherData: 'DescribeWeatherData',
  CreateMaintenanceLog: 'CreateMaintenanceLog',
  UpdateMaintenanceLog: 'UpdateMaintenanceLog',
  // Media API
  DescribeMediaStatus: 'DescribeMediaStatus',
  ReceiveMediaStream: 'ReceiveMediaStream',
  Call: 'Call',
  UpdateRemoteControlStatus: 'UpdateRemoteControlStatus',
  UpdateVisualizationStatus: 'UpdateVisualizationStatus',
} as const;

// hooks

export type ScopeFilterType = 'and' | 'or';

export const useHasFeatureScope = (): ((
  targetScope: string | string[],
  filter?: ScopeFilterType,
) => boolean) => {
  const { featureScope } = useRecoilValue(assertAtom);

  const getHasFeatureScope = useCallback(
    (targetScope: string | string[], filter: ScopeFilterType = 'and') => {
      // 引数で渡されたscopeを保持しているか
      if (Array.isArray(targetScope)) {
        // 配列の場合
        if (filter === 'and') {
          // 全て含むかどうか
          return targetScope.every((s) => featureScope.indexOf(s) >= 0);
        }
        // どれかひとつでも含むかどうか
        return targetScope.some((s) => featureScope.indexOf(s) >= 0);
      }

      // 文字列の場合
      return featureScope.includes(targetScope);
    },
    [featureScope],
  );

  return getHasFeatureScope;
};

export const useHasScope = (): ((
  targetScope: string | string[],
  filter?: ScopeFilterType,
) => boolean) => {
  const environment = useRecoilValue(environmentAtom);
  const { scope } = useRecoilValue(assertAtom);

  const scopes = useMemo(() => scope.split(' '), [scope]);

  const getHasScope = useCallback(
    (targetScope: string | string[], filter: ScopeFilterType = 'and') => {
      if (!environment) {
        // プロジェクトや走行環境が存在しない場合
        return false;
      }
      const prefix = `${environment.project_id}:environment:${environment.environment_id}=`;
      // 引数で渡されたscopeを保持しているか
      if (Array.isArray(targetScope)) {
        // 配列の場合
        if (filter === 'and') {
          // 全て含むかどうか
          return targetScope.every((s) => scopes.includes(`${prefix}${s}`));
        }
        // どれかひとつでも含むかどうか
        return targetScope.some((s) => scopes.includes(`${prefix}${s}`));
      }

      // 文字列の場合
      return scopes.includes(`${prefix}${targetScope}`);
    },
    [environment, scopes],
  );

  return getHasScope;
};

/**
 * 車両情報（Metadata, Telemetry）を取得できる権限があるかどうか
 */
export const useEnableGetVehicles = (): boolean => {
  const getHasScope = useHasScope();
  const enable = useMemo(
    () =>
      getHasScope([
        SCOPES.DescribeVehicleMetadata,
        SCOPES.DescribeVehicleTelemetry,
      ]),
    [getHasScope],
  );
  return enable;
};
